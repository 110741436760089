

export default {
    data() {
        return {
            isClosed: false,
            isVisible: false,
            isExpanded: false,
            windowWidth: typeof window !== "undefined" ? window.innerWidth : 0,
            hasAnimated: false,
            isSlidingOut: false,
        };
    },
    mounted() {
        console.log("section176 mounted", this.$attrs);
        this.windowWidth = window.innerWidth;

        if (process.client) {
            window.addEventListener("resize", this.updateWindowWidth);
            window.addEventListener("scroll", this.scrollToTopCta);
            this.scrollToTopCta(); // run initially to set visibility based on scroll
        }
    },
    beforeUnmount() {
        if (process.client) {
            window.removeEventListener("resize", this.updateWindowWidth);
            window.removeEventListener("scroll", this.scrollToTopCta);
        }
    },
    methods: {
        toggleMainContainer() {
            if (this.windowWidth <= 767) {
                this.isExpanded = !this.isExpanded;
            }
        },
        closeWidget() {
            this.isClosed = true;
        },
        scrollToTopCta() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > 500 && !this.isVisible) {
                this.isVisible = true;
                this.isSlidingOut = false;

                if (!this.hasAnimated) {
                    this.hasAnimated = true;
                }
            } else if (scrollTop <= 500 && this.isVisible) {
                this.isSlidingOut = true;

                // Wait for the animation to finish, then hide
                setTimeout(() => {
                    this.isVisible = false;
                    this.hasAnimated = false;
                    this.isSlidingOut = false;
                }, 500); // match animation duration
            }
        },
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
    },
};
